<template>
  <div id="page-container">
    <nagi-header :show-back="true" />
    <div class="content-container">
      <div class="page-header">{{ $t("privacy.header") | uppercase }}</div>
      <div class="background"></div>
      <markdown-it-vue v-if="markdownContent" class="page-content md-body" :content="markdownContent" />
      <div class="background"></div>
    </div>
    <nagi-footer />
  </div>
</template>

<script>
import MarkdownItVue from 'markdown-it-vue';
import 'markdown-it-vue/dist/markdown-it-vue.css';

const NagiHeader = () => import("../components/NagiHeader");
const NagiFooter = () => import("../components/NagiFooter");

export default {
  name: "PrivacyPolicy",
  components: {
    NagiHeader,
    NagiFooter,
    MarkdownItVue,
  },
  asyncComputed: {
    markdownContent: async function() {
      return (await import(`@/assets/docs/privacy-policy.${this.$i18n.locale}.txt`)).default;
    },
  },
};
</script>

<style>
#page-container {
  overflow: unset;
  height: 100%;
  min-height: 100vh;
  background-image: radial-gradient(transparent, black),
    url("../assets/backgrounds/bg.jpg");
  background-size: auto 130vh;
  background-position: 60% 130%;
  grid-template-rows: 48px 10fr 72px;
  grid-template-columns: 1fr;
  display: block;
  justify-items: center;
}

.content-container {
  color: white;
  display: grid;
  grid-template-rows: 20vh auto;
  grid-template-columns: 1fr 8fr 1fr;
  width: 100%;
  height: 100%;
}

.page-header {
  grid-column: 1/4;
  padding-top: 30px;
  font-size: xx-large;
}
.page-content {
  grid-column: 2/3;
  background-color: white;
  margin-top: -7%;
  margin-bottom: 0;
  color: black;
  overflow-y: hidden;
  text-align: left;
  padding: 48px;
  font-size: smaller;
}
.background {
  background-color: whitesmoke;
}

@media screen and (max-width: 1024px) {
  #page-container {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 52% 200%;
  }

  .content-container {
    grid-template-columns: 1fr 20fr 1fr;
  }
  .page-content {
    padding: 20px;
    margin-top: -10%;
  }
  .page-header{
    margin-top: 20px;
  }
}
</style>